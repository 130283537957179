<template>
  <div class="trainingWrap container">
    <header>
      <el-row class="hedaer-Bg" type="flex">
        <el-col :span="12">
          <div class="grid-content header-left-content">
            <div class="logo-wrap">
              <img src="../../assets/image/homeImg/logo.png" />
            </div>
            <div class="app-title" @click="$router.push('/home')">
              在线培训平台
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content header-right-content">
            <div @click="$router.push('/login')" v-if="userName == ''">登录</div>
            <div v-else class="userName-wrap">
              <el-dropdown placement="bottom">
                <span class="el-dropdown-link">
                  学员{{ userName }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="$router.push('/accountSafe')">账号安全</el-dropdown-item>
                  <el-dropdown-item @click.native="$router.push('/login')">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="nav_side">
        <nav>
          <router-link active-class="active" to="/home">首页</router-link>
          <router-link active-class="active" to="/trainingClass">安全培训</router-link>
          <router-link active-class="active" to="/trainingClass2">职业卫生</router-link>
          <router-link active-class="active" to="/studyCenter"
            >学习中心</router-link
          >
          <router-link active-class="active" to="/examCenter"
            >考试中心</router-link
          >
<!--          <router-link active-class="active" to="/newcomerPost"-->
<!--            >新人驿站</router-link-->
<!--          >-->
<!--          <router-link active-class="active" to="/liveRoom"-->
<!--            >直播大厅</router-link-->
<!--          >-->
<!--          <router-link active-class="active" to="/boutiqueCourse"-->
<!--            >精品课程</router-link-->
<!--          >-->
          <router-link active-class="active" to="/trainDynamic"
            >培训动态</router-link
          >
          <router-link active-class="active" to="/helpCenter"
            >帮助中心</router-link
          >
        </nav>
      </div>
    </header>
    <div class="main-content">
      <div class='navLocation'>培训动态＞正文</div>
      <div class='contentBox'>
        <div class='title'>{{contentInfo.title}}</div>
        <div class='secondTitle'>
          <!-- <div>{{contentInfo.title}}</div> -->
          <div>{{contentInfo.createTime}}</div>
          <!-- <div class='changeFontSize'>字体：【 <span @click='nowFontType=3'>大</span> <span @click='nowFontType=2'>中</span> <span @click='nowFontType=1'>小</span> 】</div> -->
        </div>
        <el-divider></el-divider>
        <div class='contentWrap' v-html="contentInfo.content" :class="getFontSizeClass"></div>
      </div>
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import footerCom from '@/components/footer.vue'
export default {
  name: '',
  components: {
    footerCom
  },
  props: {},
  watch: {},
  computed: {
    getFontSizeClass: function () {
      return 'fontSizeClass_' + this.nowFontType
    }
  },
  data () {
    return {
      userName: '',
      contentInfo: {},
      nowFontType: 2,
    }
  },
  methods: {
    getInfo (id) {
      this.$axios.get(this.$apiUrl.contentLoad, {params: {id}}).then(response => {
        let res = response.data
        console.log(res)
        if (res.success) {
          this.contentInfo = res.data
        }
      })
    },
    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
    },
  },
  mounted () {
    this.getUserInfo()
    this.getInfo(this.$route.params.dynamicId)
  },
}
</script>

<style scoped lang="scss">

@keyframes xing {
    100% {
        -webkit-transform: scale(0.5);
        opacity: 0
    }
    130% {
        -webkit-transform: scale(1);
        opacity: 1
    }
}
.container {
  position: relative;
  min-height: 100vh;
  background-color: #fbfbff;

  header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 146px;
  }

  .hedaer-Bg {
    /* background-color:#6a8dea; */
    background-size: 100% 100%;
    height: 42px;
    width: 1240px;
    margin: 0 auto;
    line-height: 56px;
    /* padding: 0 100px; */

    .header-left-content {
      font-size: 22px;
      color: white;
      font-weight: 800;
      display: flex;
      justify-content: start;

      .app-title {
        cursor: pointer;
      }

      .logo-wrap {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 42px;
        }
      }
    }

    .header-right-content {
      font-size: 18px;
      color: white;
      height: 42px;
      display: flex;
      justify-content: end;
      align-items: center;

      .userName-wrap {
        height: 34px;
        line-height: 34px;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      ::v-deep .el-dropdown {
        font-size: 18px;
        color: #4774df;
        background-color: #fff;
        height: 34px;
        line-height: 34px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        padding: 0px 10px;
      }
    }
  }

  .nav_side {
    width: 1240px;
    margin: 0 auto;
    height: 61px;
    line-height: 61px;

    nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 22px;
      font-weight: bold;
    }

    nav a {
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 61px;
    }

    nav a:hover {
      opacity: 1;
    }

    .active {
      font-size: 26px;
      font-weight: bold;
      color: #ffffff;
      opacity: 1;
      border-bottom: 3px solid #ffffff;
    }
  }
}
  .main-content {
    width: 737px;
    padding-bottom: 188px;
    margin:  0 auto 0;
    padding: 30px;
    padding-bottom: 188px;
    background-color: transparent;
    .navLocation{
      margin-bottom: 30px;
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }
    .changeFontSize{
      >span{
        cursor: pointer;
      }
      >span:hover{
        color: #4774df
      }
    }
    .title{
      display: flex;
      justify-content: center;
      font-size: 32px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #1C4ECC;
      margin-bottom: 30px;
    }
    .secondTitle{
      display: flex;
      justify-content: space-around;
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: rgba(0,0,0,0.6);
    }
    .contentWrap{
      margin-bottom: 60px;
      ::v-deep p{
        font-size: 28px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
      ::v-deep img{
        width: 100%;
        height: 100%;
      }
    }
    /deep/ .fontSizeClass_1{
      font-size: 12px;
    }
    /deep/  .fontSizeClass_2{
      font-size: 18px;
    }
    /deep/  .fontSizeClass_3{
      font-size: 24px;
    }
  }
  /deep/  .el-carousel{
    width: 934px
  }
</style>
